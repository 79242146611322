<template>
  <v-container fluid class="pa-0 elevation-1 mx-1">
    <v-toolbar color="secondary" flat rounded>
      <v-toolbar-title class="white--text">Categories</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-dialog v-model="dialog" max-width="800px">
        <template v-slot:activator="{ on }">
          <v-btn 
            class="ms-2 primary"
            small
            rounded
            v-on="on"
          >
            Add Category
          </v-btn>
        </template>
        <v-form v-model="dialogValid" ref="dialog">
          <v-card class="mx-auto card--flex-toolbar">
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text class="card-body">
              <v-container>
                <v-sheet class="pa-4 primary lighten-2">
                  <v-text-field
                    v-model="search"
                    label="Search Category"
                    dark
                    flat
                    solo-inverted
                    hide-details
                    clearable
                    clear-icon="mdi-close-circle-outline"
                  ></v-text-field>
                </v-sheet>
                <v-treeview
                  dense
                  open-all
                  :active.sync="active"
                  :search="search"
                  :items="categories"
                  activatable
                  @update:active="crawl(categories, active[0])"
                ></v-treeview>
                <v-breadcrumbs v-show="active" :items="path">
                  <template v-slot:divider>
                    <v-icon>mdi-forward</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-container>
            </v-card-text>
            <v-card-actions class="card-action">
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined small @click="cardClose"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                class="mr-4"
                small
                @click="cardSave"
                :disabled="!dialogValid"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-toolbar>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-start">Name</th>
            <th class="text-start">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in localData" :key="idx">
            <td>
              <v-breadcrumbs :items="item.path">
                <template v-slot:divider>
                  <v-icon>mdi-forward</v-icon>
                </template>
              </v-breadcrumbs>
            </td>
            <td>
              <v-icon small class="mr-2" @click="editItem(item)"
                >mdi-pencil-outline</v-icon
              >
              <v-icon small class="mr-2" @click="deleteItem(item)"
                >mdi-delete-outline</v-icon
              >
            </td>
          </tr>
          <tr v-if="!localData.length">
            No Category
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  props: {
    items: { type: Array },
  },
  beforeMount() {
    this.localData = this.items;
  },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["darkTheme"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Category" : "Edit Category";
    },
  },
  data() {
    return {
      localData: "",
      categories: [],
      search: null,
      active: [],

      dialog: false,
      dialogValid: true,
      editedIndex: -1,
      path: [],
    };
  },
  watch: {
    dialog(val) {
      val || this.cardClose();
    },
    //items(newVal, oldVal) {
    items() {
      this.localData = this.items;
      //console.log(this.localData);
      //console.log("Topics prop change: ", newVal, " | was: ", oldVal);
    },
  },
  created() {
    this.categories = this.settings.find((e) => {
      return e.id == "categories";
    }).param;
  },
  methods: {
    crawl(o, id) {
      this.path = [];
      if (id != undefined) {
        let root = o.find((element) => {
          return this.searchTree(element, id);
        });
        if (root) {
          let bcData = { id: root.id, text: root.name, disabled: false };
          this.path.unshift(bcData);
        }
      }
    },
    searchTree(o, id) {
      // get out if ID passed in is null or empty
      if (id == null || id.length == 0) {
        return null;
      }
      // found match
      if (o.id === id) {
        return o;
      }
      let result;
      // go through the children and stop the moment a match is found
      o.children.some((element) => {
        result = this.searchTree(element, id);
        if (result) {
          // recursive call found the item we need
          let bcData = { id: element.id, text: element.name, disabled: false };
          this.path.unshift(bcData);
          return result;
        }
      });
      return result;
    },
    editItem(item) {
      //console.log(item);
      //   this.editedIndex = item;
      //   this.editedItem = this.localData[item];
      this.editedIndex = this.localData.indexOf(item);
      // prepopulate the path for the user to edit
      let last = item.path[item.path.length - 1];
      this.path = item.path;
      this.search = last.text;
      this.active = [last.id];
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.localData.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.$emit("deleteCategory", { index: index });
      //this.localData.splice(index, 1);
    },
    cardClose() {
      this.dialog = false;
      // not sure how to delay this properly
      setTimeout(() => {
        this.$nextTick(() => {
          this.path = [];
          this.editedIndex = -1;
          this.active = [];
          this.search = "";
        });
      }, 200);
    },
    cardSave() {
      this.$emit("newTopics", {
        editedIndex: this.editedIndex,
        editedItem: { path: this.path },
      });
      this.cardClose();
    },
  },
};
</script>

<style>
.card--flex-toolbar {
  margin-top: -66px;
}
.card-body {
  overflow-y: auto;
  max-height: 80vh;
}
.card-action {
  margin-top: -20px;
}
</style>