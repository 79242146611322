<template>
  <v-data-table
    class="elevation-1 mx-1"
    hide-default-footer
    :headers="headers"
    :items="localData"
  >
    <template v-slot:top>
      <v-toolbar color="secondary" flat rounded>
        <v-toolbar-title class="white--text">Case Title</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn 
              v-on="on"
              class="ms-2 primary"
              small
              rounded
            >
              Add Case Reference
            </v-btn>
          </template>
          <v-form v-model="dialogValid" ref="dialog">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="2" md="2">
                      <v-select
                        v-model.trim="editedItem.prefix"
                        :items="localTypes"
                        label="Type"
                      />
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                        label="Reference Number"
                        v-model.trim="editedItem.number"
                        :rules="fieldRules"
                        required
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="Short Title"
                        v-model="editedItem.short"
                        :rules="fieldRules"
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-textarea
                        label="Title"
                        v-model.trim="editedItem.title"
                        :rules="fieldRules"
                        required
                        solo-filled
                        auto-grow
                        outlined
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" small outlined @click="cardClose"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  @click="cardSave"
                  small
                  :disabled="!dialogValid"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil-outline</v-icon>
      <v-icon small @click="deleteItem(item)">mdi-delete-outline</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    items: { type: Array },
    types: { type: Array },
  },
  beforeMount() {
    this.localData = this.items;
    this.localTypes = this.types;
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Reference" : "Edit Reference";
    },
    ...mapGetters(["darkTheme"]),
  },
  data() {
    return {
      localData: "",
      localTypes: [],
      fieldRules: [(v) => !!v || "Field is required"],
      // everything related to the issues table
      headers: [
        { text: "Type", value: "prefix", sortable: false },
        {
          text: "Reference #",
          align: "start",
          sortable: false,
          value: "number",
        },
        { text: "Short Title", value: "short", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      dialog: false,
      dialogValid: true,
      editedIndex: -1,
      editedItem: {
        prefix: "G.R. No.",
        number: "",
        refno: "",
        title: "",
        short: "",
      },
      defaultItem: {
        prefix: "G.R. No.",
        number: "",
        refno: "",
        title: "",
        short: "",
      },
    };
  },
  watch: {
    dialog(val) {
      val || this.cardClose();
    },
    //items(newVal, oldVal) {
    items() {
      this.localData = this.items;
      //console.log("Issues prop change: ", newVal, " | was: ", oldVal);
    },
  },
  methods: {
    // everything related to the issues table
    editItem(item) {
      this.editedIndex = this.localData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.localData.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.$emit("deleteReference", { index: index });
      //this.localData.splice(index, 1);
    },
    cardClose() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    cardSave() {
      // saving data emits an event for the caller to do the adding of reference to the case
      this.editedItem.refno =
        this.editedItem.prefix + " " + this.editedItem.number;
      this.$emit("newReference", {
        editedIndex: this.editedIndex,
        editedItem: this.editedItem,
      });
      //   if (this.editedIndex > -1) {
      //     Object.assign(this.localData[this.editedIndex], this.editedItem);
      //   } else {
      //     this.localData.push(this.editedItem);
      //   }
      this.cardClose();
    },
  },
};
</script>