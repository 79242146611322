<template>
  <v-responsive min-width="500px" max-width="1300px">
    <v-card :class="themeClass">
      <v-container>
        <v-form ref="form" v-model="formValid" lazy-validation>
          <v-row class="mb-3">
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                label="Index Number"
                hint="e.g. SCRA, Phil"
                persistent-hint
                v-model="grno.idx"
              />
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <span class="subtitle-2">Tier Classification</span>
              <v-chip-group>
                <v-chip small v-if="tag1">{{ indexOptions[0] }}</v-chip>
                <v-chip small v-if="tag2">{{ indexOptions[1] }}</v-chip>
                <v-chip small v-if="tag3">{{ indexOptions[2] }}</v-chip>
                <v-chip small v-if="tag4">{{ indexOptions[3] }}</v-chip>
              </v-chip-group>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-menu
                v-model="pubDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="caseDate"
                    label="Promulgated"
                    hint="Month Day, Year"
                    persistent-hint
                    :rules="[(v) => checkDate(v)]"
                    prepend-icon="event"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  landscape
                  v-model="dateFormatted"
                  @input="pubDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <CaseEditReferences
            :items="grno.references"
            :types="prefix"
            @newReference="updateReference($event)"
            @deleteReference="deleteReference($event)"
            class="mb-4"
          />

          <CaseEditTopics
            :items="grno.categories"
            @newTopics="updateCategory($event)"
            @deleteCategory="deleteCategory($event)"
            class="mb-4"
          />

          <div class="form-group px-1 mb-4">
            <label>Case Brief</label>
            <ckeditor
              :editor="editor"
              v-model="grno.brief"
              :config="editorConfig"
            ></ckeditor>
          </div>
          <div class="form-group px-1 mb-4">
            <label>Case Digest</label>
            <ckeditor
              :editor="editor"
              v-model="grno.digest"
              :config="editorConfig"
            ></ckeditor>
          </div>
          <CaseEditIssues
            :items="issues"
            @newIssue="updateIssue($event)"
            @deleteIssue="deleteIssue($event)"
            class="mb-4"
          />
          <v-row>
            <v-col cols="12" sm="8" md="6">
              <v-file-input
                v-if="!uploadEnd && !uploading && !loadingFile"
                v-model="file"
                accept="application/pdf"
                label="Full Text PDF"
              />
              <v-progress-circular
                v-if="uploading && !uploadEnd"
                :size="100"
                :width="15"
                :rotate="360"
                :value="progressUpload"
                color="primary"
              >{{ progressUpload }}%</v-progress-circular>
              <v-progress-linear
                v-if="loadingFile" 
                color="primary"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
              <v-list v-if="uploadEnd">
                <v-subheader>Full Text PDF</v-subheader>
                <v-card flat>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-file-pdf-box</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ fileName }}</v-list-item-title>
                      <v-btn
                        class="ma-0"
                        small
                        rounded
                        outlined
                        color="error"
                        @click="clearFileUI"
                      >Delete File</v-btn>
                  </v-list-item>
                </v-card>
                
              </v-list>
            </v-col>
          </v-row>
          <v-checkbox
            class="mx-4"
            v-if="formNew || draft"
            v-model="casePublish"
            label="Publish Draft Case"
          />
          <v-btn
            color="primary"
            class="ma-4"
            @click="prepSubmit"
            :disabled="!formValid"
          >{{ saveText }}</v-btn>
          <v-snackbar :color="snackColor" v-model="snackbar" :timeout="1000">{{
            response
          }}</v-snackbar>
        </v-form>
      </v-container>
    </v-card>
  </v-responsive>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { db, } from "../../firebaseDb";
import firebase from "firebase/app";
import moment from "moment";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import CaseEditReferences from "./CaseEditReferences";
import CaseEditIssues from "./CaseEditIssues";
import CaseEditTopics from "./CaseEditTopics";
import axios from 'axios';
import { retrieveFile } from "../utils/retrieveFileUtils";

// Handling of issues has been moved to CaseEditIssues due to the size of the component

export default {
  components: {
    // Use the <ckeditor> component only in this view.
    ckeditor: CKEditor.component,
    CaseEditReferences,
    CaseEditIssues,
    CaseEditTopics,
  },
  data() {
    return {
      loadingFile: false,
      paramId: this.$route.params.id,
      table: "cases",
      draft: false,
      snackbar: false,
      response: "",
      snackColor: "black",
      casePublish: false,
      formNew: true,
      formValid: true,
      fieldRules: [(v) => !!v || "Field is required"],
      oldRecord: {},
      grno: {
        uid: "",
        issues: [],
        categories: [],
        references: [],
        tiers: []
      },
      indexOptions: ['Tier 1', 'Tier 2', 'Tier 3', 'Tier 4'],
      tag1: false, 
      tag2: false, 
      tag3: false, 
      tag4: false,

      // file handler
      oldPath: "",
      progressUpload: 0,
      fileName: "",
      uploadTask: "",
      uploading: false,
      uploadEnd: false,
      deletePath: "",
      uploadName: "",
      uploadItem: "",
      file: [],

      // issues
      issues: [],
      issueSet: [],
      deleteList: [],
      updateIssues: false,

      // ckeditor
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "bulletedList",
            "numberedList",
            "|",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
        },
      },
      // date picker - need to separate the datepicker and text
      // so that it won't error when user enters an invalid date
      caseDate: "",
      dateFormatted: "",
      pubDateMenu: false,

      statuses: ["Controlling", "Repealed", "Reinstated"],
      casePrefix: "G.R. No.",
      prefix: [],
      categories: [],
      references: [],
    };
  },
  created() {
    if (this.paramId === "new") {
      this.formNew = true;
    } else {
      if (this.$route.params.draft) {
        this.table = "drafts";
        this.draft = true;
      }
      let dbRef = db.collection(this.table).doc(this.paramId);
      dbRef
        .get()
        .then((doc) => {
          this.formNew = false;
          this.oldRecord = doc.data();
          this.grno = doc.data();
          this.grno.uid = this.paramId;
          // console.log(this.grno);
          this.issueSet = this.grno.issues;
          this.grno.issues.forEach((element) => {
            element.get().then((res) => {
              let issueData = res.data();
              issueData.ref = element;
              if (typeof issueData.related != "undefined") {
                if (this.draft) {
                  // draft cases stores the link directly in the related array
                  issueData.links = JSON.parse(
                    JSON.stringify(issueData.related)
                  );
                  delete issueData.related;
                  this.issues.push(issueData);
                  this.updateIssues = true;
                } else {
                  // published cases store the reference to the issuelinks collection
                  if (issueData.related) {
                    issueData.links = [];
                    // related is a reference to the issuelinks collection
                    issueData.related.get().then((il) => {
                      // data contains a single property called links and it is an array
                      // each item in the array is an issue
                      il.data().links.forEach((iss) => {
                        //don't add the issue in the links if it belongs to this case
                        if (this.grno.uid != iss.caseRef) {
                          // console.log(iss);
                          iss.related = issueData.related.id;
                          issueData.links.push(JSON.parse(JSON.stringify(iss)));
                        }
                      });
                      this.issues.push(issueData);
                      this.issues.sort((a, b) => a.issueId - b.issueId);
                    });
                  }
                }
              } else {
                issueData.links = [
                  {
                    caseRef: "",
                    issueRef: "",
                    readonly: false,
                  },
                ];
                this.issues.push(issueData);
              }
              // console.log(this.issues);
            });
          });
          if (typeof this.grno.references == "undefined") {
            this.grno.references = [];
          }
          if (typeof this.grno.categories == "undefined") {
            this.grno.categories = [];
          }
          //console.log("change the firestore timestamp to datetime-local");
          //console.log(this.grno);
          // this is for the text field
          this.caseDate = moment
            .utc(this.grno.date.toDate())
            .local()
            .format("LL");
          // this is for the date picker
          this.dateFormatted = moment
            .utc(this.grno.date.toDate())
            .local()
            .format("YYYY-MM-DD");
          // console.log(this.grno.date);
          // console.log("turn back to timestamp");
          // console.log(
          //   firebase.firestore.Timestamp.fromDate(
          //     new Date(this.caseDate)
          //   ).toDate()
          // );
          // console.log("date change done");
        })
        .catch((error) => {
          this.formNew = true;
          console.log(error);
        });
      this.retrieveFileWrapper();
    }
    //console.log(this.draft);
    this.getSettings();
  },
  mounted() {
    //retrieve for issues editing
    this.$store.dispatch("streamCaseListsFiltered", {
      id: this.paramId,
    });
  },

  methods: {
    updateTagsBasedOnFirstIssue() {
      if (this.issues.length > 0) {
        const firstIssue = this.issues[0];
        this.tag1 = firstIssue.status === 'Controlling' && firstIssue.landmark === 'Landmark';
        this.tag2 = firstIssue.status === 'Controlling' && firstIssue.landmark === 'Not Landmark';
        this.tag3 = (firstIssue.status === 'Controlling' || firstIssue.status === 'Reinstated') && 
                    (firstIssue.landmark === 'Landmark' || firstIssue.landmark === 'Not Landmark');
        this.tag4 = (firstIssue.status === 'Reinstated' || firstIssue.status === 'Controlling' || firstIssue.status === 'Repealed') &&
                    (firstIssue.landmark === 'Landmark' || firstIssue.landmark === 'Not Landmark');
        this.updateTiers();
      } else {
        this.tag1 = this.tag2 = this.tag3 = this.tag4 = false;
        this.updateTiers();
      }
    },

    handleTagVisibilityUpdated(statusRef, landmarkRef) {
      this.tag1 = statusRef === 'Controlling' && landmarkRef === 'Landmark';
      this.tag2 = statusRef === 'Controlling' && landmarkRef === 'Not Landmark';
      this.tag3 = (statusRef === 'Controlling' || statusRef === 'Reinstated') && 
                  (landmarkRef === 'Landmark' || landmarkRef === 'Not Landmark');
      this.tag4 = (statusRef === 'Reinstated' || statusRef === 'Controlling' || statusRef === 'Repealed') &&
                  (landmarkRef === 'Landmark' || landmarkRef === 'Not Landmark');
      this.updateTiers();
    },

    updateTiers() {
      this.grno.tiers = [];
      if (this.tag1) this.grno.tiers.push('Tier1');
      if (this.tag2) this.grno.tiers.push('Tier2');
      if (this.tag3) this.grno.tiers.push('Tier3');
      if (this.tag4) this.grno.tiers.push('Tier4');

      // console.log(this.grno.tiers)
    },

    getSettings() {
      if (this.settings.length == 0) {
        this.$store.dispatch("fetchSettings");
      }
      this.prefix = this.settings.find((e) => {
        return e.id == "prefix";
      }).param;
      this.categories = this.settings.find((e) => {
        return e.id == "categories";
      }).param;
    },
    checkDate(date) {
      if (!moment(date, "LL", true).isValid()) {
        return "Invalid Date";
      }
      this.caseDate = date;
      return true;
    },

    // dealing with dialogs (References, Categories, and Issues)
    // is there a way to combine events for different arrays?
    // handling of these arrays are identical and should be merged
    deleteCategory(item) {
      this.grno.categories.splice(item.index, 1);
    },
    deleteIssue(item) {
      if (typeof this.issues[item.index].ref != "undefined") {
        this.deleteList.push(this.issues[item.index].ref);
      }
      this.issues.splice(item.index, 1);
    },
    deleteReference(item) {
      this.grno.references.splice(item.index, 1);
    },
    updateCategory(edited) {
      if (edited.editedIndex > -1) {
        this.$set(this.grno.categories, edited.editedIndex, edited.editedItem);
      } else {
        this.grno.categories.push(edited.editedItem);
      }
    },
    updateIssue(edited) {
      this.updateIssues = true; // issues group is flagged for updating
      edited.editedItem.updated = true; // single record flag for updating
      if (edited.editedIndex > -1) {
        this.$set(this.issues, edited.editedIndex, edited.editedItem);
      } else {
        this.issues.push(edited.editedItem);
      }
      this.issues.sort((a, b) => a.issueId - b.issueId);
      this.updateTagsBasedOnFirstIssue();
    },
    updateReference(edited) {
      if (edited.editedIndex > -1) {
        this.$set(this.grno.references, edited.editedIndex, edited.editedItem);
      } else {
        this.grno.references.push(edited.editedItem);
      }
    },

    // saving the firebase document
    prepSubmit() {
      event.preventDefault();
      let valid = this.$refs.form.validate();
      // console.log("saving");
      // console.log(this.oldRecord);
      // console.log(this.grno);
      this.grno.date = firebase.firestore.Timestamp.fromDate(
        new Date(this.caseDate)
      );
      // TODO: issue history needs to be calculated
      if (!this.issues.length) {
        valid = false;
        this.snackbar = true;
        this.snackColor = "fail";
        this.response = "Must have at least 1 issue";
      }
      if (valid) {
        if (this.updateIssues) {
          this.collateIssues().then((res) => {
            this.issueSet = res;
            this.updateCase();
            this.deleteIssuesCollection();
          });
        } else {
          this.updateCase();
        }
      }
    },
    collateIssues() {
      let promise = [];
      // go through each of the issues in the case
      // create or update the issues
      this.issues.forEach((e) => {
        // look for the the case id
        if (!this.formNew) {
          e.case = db.doc(this.table + "/" + this.paramId);
        }
        // check if this is an existing issue. ref should have been populated during creation hook.
        let issueDBRef = "";
        if (typeof e.ref == "undefined") {
          // create a new issues record
          issueDBRef = db.collection("issues").doc();
        } else {
          // update existing issues record
          issueDBRef = db.collection("issues").doc(e.ref.id);
        }
        promise.push(
          new Promise((resolve) => {
            issueDBRef.set(e).then(() => {
              // console.log(issueDBRef.id);
              // capture the document reference
              // will be used later on to link to the case document
              if (typeof e.ref == "undefined") {
                e.ref = db.doc("issues/" + issueDBRef.id);
              }
              // return the self reference for the case document
              resolve(e.ref);
              // get rid of this self reference. we don't need it
              e.ref.update({ ref: firebase.firestore.FieldValue.delete() });
            });
          })
        );
      });
      return Promise.all(promise);
    },
    updateCase() {
      delete this.grno.uid;
      // strip VueJS observers before sending to firestore
      let newCase = { ...this.grno };
      // replace the issues with references to the issues collection
      newCase.issues = this.issueSet;
      let updateLinkIssueToCase = false;
      if ((this.formNew || this.draft) && !this.casePublish) {
        // console.log("New " + this.formNew);
        // console.log("draft " + this.draft);
        // console.log("publish " + this.casePublish);
        // document is New or Draft
        // document has never been published
        let draftDBRef = "";
        if (this.formNew) {
          // auto-generate ID
          draftDBRef = db.collection("drafts").doc();
          newCase.author = this.currentUser.uid;
          newCase.authorName = this.currentUser.displayName;
          updateLinkIssueToCase = true;
        } else {
          // use ID parameter
          draftDBRef = db.collection("drafts").doc(this.paramId);
        }
        draftDBRef
          .set(newCase)
          .then(() => {
            // file check
            this.prepUpload(draftDBRef.id);

            // update the issues
            if (updateLinkIssueToCase || this.updateIssues) {
              this.issueSet.forEach((e) => {
                if (updateLinkIssueToCase) {
                  // store the case reference for the issue
                  e.update({ case: db.doc("drafts/" + draftDBRef.id) });
                }
                e.get().then((iss) => {
                  // console.log("draft iss.data()");
                  // console.log(iss.data());
                  // drafts store the links to related property
                  if (iss.data().updated) {
                    e.update({
                      related: JSON.parse(JSON.stringify(iss.data().links)),
                    });
                    e.update({
                      deleted: firebase.firestore.FieldValue.delete(),
                      links: firebase.firestore.FieldValue.delete(),
                    });
                  }
                });
              });
            }
            this.snackbar = true;
            this.snackColor = "success";
            this.response = "Draft successfully saved!";
            setTimeout(() => this.$router.push("/draft-list"), 1000);
          })
          .catch((error) => {
            // console.log(error);
            this.snackbar = true;
            this.snackColor = "fail";
            this.response = error;
          });
      } else {
        // Published is set to TRUE
        // - Existing published case updated by the editor
        // - New entry that skipped draft stage
        // - - need the system to auto-generate ID for the cases document set
        // - Recently been published moving the data from draft to cases
        // - - need the system to auto-generate ID for the cases document set
        // - - delete draft
        // - - move the file to the cases document
        let caseDBRef = "";
        if (this.formNew || this.draft) {
          // auto-generate ID
          caseDBRef = db.collection("cases").doc();
          updateLinkIssueToCase = true;
          newCase.publisher = this.currentUser.uid;
          newCase.publisherName = this.currentUser.displayName;
        } else {
          // use ID parameter
          caseDBRef = db.collection("cases").doc(this.paramId);
          newCase.editor = this.currentUser.uid;
          newCase.editorName = this.currentUser.displayName;
        }
        if (this.formNew) {
          newCase.author = this.currentUser.uid;
          newCase.authorName = this.currentUser.displayName;
        }
        caseDBRef
          .set(newCase)
          .then(() => {
            this.prepUpload(caseDBRef.id);
            if (updateLinkIssueToCase || this.updateIssues) {
              this.updateLinks(caseDBRef.id, {
                updateCase: updateLinkIssueToCase,
              });
            }
            this.snackbar = true;
            this.snackColor = "success";
            this.response = "Case successfully updated!";
            if (this.draft) {
              // Only move if old file exists and no new file
              if (
                this.oldPath ==
                "fulltext/" + this.paramId + "/" + this.fileName
              ) {
                // MOVE FILE HOW?
                let dest = "fulltext/" + caseDBRef.id + "/" + this.fileName;
                this.moveFile(dest);
                // update the old path so that it doesn't redo the copy
                this.oldPath = dest;
              }
              db.collection("drafts").doc(this.paramId).delete();
              // go back to the draft list
              setTimeout(() => this.$router.push("/draft-list"), 1000);
            } else {
              // go back to the case list
              setTimeout(() => this.$router.push("/case-list"), 1000);
            }
          })
          .catch((error) => {
            // console.log(error);
            this.snackbar = true;
            this.snackColor = "fail";
            this.response = error;
          });
      }
    },
    deleteIssuesCollection() {
      this.deleteList.forEach((e) => {
        if (!this.draft) {
          e.get().then((iss) => {
            if (typeof iss.data().related != "undefined") {
              iss
                .data()
                .related.get()
                .then((rel) => {
                  let newLinks = rel.data();
                  newLinks.links.splice(
                    newLinks.links.findIndex((x) => x.issueRef == iss.id),
                    1
                  );
                  // check if there are more than one case left in the list
                  let unique = newLinks.links.filter((value, index, self) => {
                    return (
                      self.findIndex((v) => v.caseRef === value.caseRef) ===
                      index
                    );
                  });
                  // if only one case left then delete the issueslink
                  if (unique.length == 1) {
                    // go through the issues of the list and remove reference
                    newLinks.links.forEach((a) => {
                      db.doc("issues/" + a.issueRef).update({
                        related: firebase.firestore.FieldValue.delete(),
                      });
                    });
                    // delete the issueslink record
                    iss.data().related.delete();
                  } else {
                    // multiple issues exists - only need to remove self
                    rel.ref.update(newLinks);
                  }
                  e.delete();
                });
            } else {
              e.delete();
            }
          });
        } else {
          e.delete();
        }
      });
    },
    updateLinks(id, param) {
      // - update the collated issues to link to the cases document
      // - update issues linked by the collated issues
      this.issueSet.forEach((e) => {
        if (param.updateCase) {
          e.update({ case: db.doc("cases/" + id) });
        }
        // console.log("e");
        // console.log(e);
        e.get().then((iss) => {
          // console.log("e.get() iss");
          // console.log(iss);
          // console.log(iss.data());
          if (this.draft) {
            // console.log("this is drafts");
            // remove the draft's temporary data in related
            // console.log(iss.data().links);
            delete iss.data().related;
            delete iss.data().deleted;
            // force issue to be updated
            iss.data().updated = true;
          }
          let issuelinks = { links: [] };
          let self = {
            caseRef: iss.data().case.id,
            date: this.grno.date.seconds,
            issueRef: iss.id,
            readonly: true,
          };
          if (iss.data().updated) {
            // skip issues that do not have any issues linked
            // console.log(iss.data().related);
            // console.log(iss.data().links);
            if (typeof iss.data().related != "undefined") {
              // this issue has an existing issuelinks
              // console.log("this issue has an existing issuelinks");
              iss
                .data()
                .related.get()
                .then((rel) => {
                  // console.log(rel);
                  // console.log(rel.data());
                  let newLinks = rel.data();
                  // this issue has completely removed all links
                  if (iss.data().links && iss.data().links[0].caseRef == "") {
                    // remove self from list
                    newLinks.links.splice(
                      newLinks.links.findIndex((x) => x.issueRef == iss.id),
                      1
                    );
                    // check if there are more than one case left in the list
                    let unique = newLinks.links.filter((value, index, self) => {
                      return (
                        self.findIndex((v) => v.caseRef === value.caseRef) === index
                      );
                    });
                    // if only one case left then delete the issueslink
                    if (unique.length == 1) {
                      // go through the issues of the list and remove reference
                      newLinks.links.forEach((a) => {
                        db.doc("issues/" + a.issueRef).update({
                          related: firebase.firestore.FieldValue.delete(),
                        });
                      });
                      // delete the issueslink record
                      iss.data().related.delete();
                    } else {
                      // multiple issues exists - only need to remove self
                      rel.ref.update(newLinks);
                    }
                    // delete the related property of this issue
                    iss.ref.update({
                      related: firebase.firestore.FieldValue.delete(),
                      deleted: firebase.firestore.FieldValue.delete(),
                    });
                  } else {
                    // other issues link exists
                    // console.log("other issues link exists");
                    if (typeof iss.data().deleted != "undefined") {
                      // there's a link that has been deleted
                      // remove it from the issuelink
                      // remove the reference in that issue
                      iss.data().deleted.forEach((d) => {
                        newLinks.links.splice(
                          newLinks.links.findIndex(
                            (x) => x.issueRef == d.issueRef
                          ),
                          1
                        );
                        db.doc("issues/" + d.issueRef).update({
                          related: firebase.firestore.FieldValue.delete(),
                        });
                      });
                      rel.ref.update(newLinks);
                      iss.ref.update({
                        deleted: firebase.firestore.FieldValue.delete(),
                      });
                    }
                    if (
                      iss.data().links &&
                      iss.data().links.findIndex((x) => "updated" in x)
                    ) {
                      iss.data().links.forEach((li) => {
                        if (li.updated) {
                          // console.log(li);
                          delete li.updated;
                          li.readonly = true;
                          newLinks.links.push(li); // add this issue to the issuelinks
                          // TODO: check if this issue already belongs to an existing issuelinks and deal with it
                          db.doc("issues/" + li.issueRef).update({
                            related: db.doc("issuelinks/" + rel.id),
                          });
                        }
                      });
                      newLinks.links.sort((a, b) => b.date - a.date);
                      // console.log(newLinks);
                      rel.ref.update(newLinks);
                    }
                  }
                });
            } else if (
              iss.data().links &&
              iss.data().links[0].caseRef != ""
            ) {
              // console.log("issue doesn't have an existing issuelinks");
              // this issue doesn't have an existing issuelinks
              let issuelinksDBRef = "";
              if (
                iss.data().links &&
                iss.data().links.findIndex((x) => "related" in x) >= 0
              ) {
                // find an existing issuelinks reference from the linked issues
                let tempRel = iss.data().links.find((x) => "related" in x);
                issuelinksDBRef = db
                  .collection("issuelinks")
                  .doc(tempRel.related);
                issuelinksDBRef.get().then((rel) => {
                  // console.log(rel.data());
                  issuelinks = rel.data();
                  issuelinks.links.push(self);
                  issuelinks.links.sort((a, b) => b.date - a.date);
                  issuelinksDBRef.set(issuelinks).then(() => {
                    // update this issue to link to the new issuelinks
                    e.update({
                      related: db.doc("issuelinks/" + issuelinksDBRef.id),
                    });
                  });
                });
              } else {
                // create the issuelinks and then attach to the issue
                issuelinksDBRef = db.collection("issuelinks").doc();
                issuelinks.links = JSON.parse(
                  JSON.stringify(iss.data().links)
                );
                issuelinks.links.forEach((a) => {
                  a.readonly = true;
                  delete a.updated;
                });
                issuelinks.links.push(self);
                issuelinks.links.sort((a, b) => b.date - a.date);
                issuelinksDBRef.set(issuelinks).then(() => {
                  // update this issue to link to the new issuelinks
                  e.update({
                    related: db.doc("issuelinks/" + issuelinksDBRef.id),
                  });
                  // update the other issues to point to the new issuelinks
                  iss.data().links.forEach((j) => {
                    // TODO: will need to cleanup and ensure the other existing links do not become orphaned
                    db.collection("issues")
                      .doc(j.issueRef)
                      .update({
                        related: db.doc("issuelinks/" + issuelinksDBRef.id),
                      });
                  });
                });
              }
            }
          }
          e.update({
            links: firebase.firestore.FieldValue.delete(),
            updated: firebase.firestore.FieldValue.delete(),
          });
        });
      });
    },

    // file management
    async prepUpload(id) {
      // Check if there is no new file but an existing file is present
      if (!this.fileName && this.oldPath) {
        await this.deleteFile();
      }
      // Check if a file is retrieved or uploaded
      if (this.fileName) {
        let path = `fulltext/${id}/${this.fileName}`;

        // Check if it's a different file
        if (path !== this.oldPath) {
          // If an existing file needs to be replaced
          if (this.oldPath) {
            await this.deleteFile();
            //console.log("Delete operation executed");
          }
          // Upload the new file
          this.upload(path, id);
        }
      }
    },

    upload(path, id) {
      this.uploading = true;

      // Assuming `this.file` is a File object representing the file to upload
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('folderName', id);  // Include the folderName in the FormData
      formData.append('author', this.currentUser.displayName)

      // Adjust the URL to point to your PHP script on Bluehost
      const bluehostUploadUrl = `${process.env.VUE_APP_API_BASE_URL}/uploadFile.php`;

      axios.post(bluehostUploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          // Handle upload progress
          this.progressUpload = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
        },
      })
      .then((response) => {
        // Handle successful upload
        console.log('File uploaded successfully:', response.data);
        this.uploadEnd = true;
      })
      .catch((error) => {
          console.error('Error uploading file to Bluehost:', error);
          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Response data:', error.response.data);
              console.error('Response status:', error.response.status);
              console.error('Response headers:', error.response.headers);
          } else if (error.request) {
              // The request was made but no response was received
              console.error('No response received:', error.request);
          } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error setting up the request:', error.message);
          }
      })
      .finally(() => {
        this.uploading = false;
      });
    },

    clearFileUI() {
      this.uploading = false;
      this.uploadEnd = false;
      this.fileName = ""; 
      //this.prepUpload(this.$route.params.id);
    },

    deleteFile() {
      // console.log('Delete file method invoked');
      if (this.oldPath) {
        const nPath = 'fulltext/' + this.$route.params.id; 
        axios.delete(`${process.env.VUE_APP_API_BASE_URL}/deleteFile.php?folderPath=${nPath}`)
          .then(() => {
            // Handle successful deletion
            console.log('File deleted successfully.');
          })
          .catch((error) => {
            console.error('Error deleting file from Bluehost:', error);
          });
      } else {
        console.error('Old path is not available. Cannot delete file.');
      }
    },

    async retrieveFileWrapper() {
      this.loadingFile = true;
      this.paramID = this.$route.params.id;
      const filePath = "fulltext/" + this.paramID;

      try {
        const { fileUrl, filename } = await retrieveFile(filePath);
        this.uploadEnd = true;
        this.fileName = filename;
        this.oldPath = 'fulltext/' + this.paramID + '/' + this.fileName;
        this.pdfUrl = fileUrl;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.handleError(error.response);
        } else {
          console.error('An unexpected error occurred:');
        }
      } finally {
        this.loadingFile = false;
      }
    },

    handleError(response) {
      // Handle different types of errors based on the response
      if (response.status === 404) {
        console.error('File not found:', response.data.message);
        // Display a user-friendly message or take other actions
        this.errorMessage = 'File not found. Please check the file path and try again.';
      } else {
        console.error('An error occurred:', response.data.message);
        // Handle other types of errors
        this.errorMessage = 'An error occurred while retrieving the file.';
      }
    },

    moveFile(dest) {
      const oldFilePath = this.oldPath; // Replace with the actual old file path

      // Adjust the URL to point to your PHP script on Bluehost
      const moveFileUrl = `${process.env.VUE_APP_API_BASE_URL}/moveFile.php?filePath=${oldFilePath}&dest=${dest}`;

      axios.get(moveFileUrl)
        .then((response) => {
          // Handle the response as needed
          console.log(response.data);
        })
        .catch((error) => {
          console.error('Error moving file:', error);
        });
    },
  },
  computed: {
    ...mapState(["currentUser", "settings"]),
    ...mapGetters(["darkTheme"]),
    themeClass() {
      return this.darkTheme ? 'dark-theme' : 'light-theme';
    },

    saveText() {
      let saveText = "Save";
      if (this.formNew || this.draft) {
        // the only scenario it can be saved to the draft
        saveText = "Save Draft";
      }
      if (this.casePublish) {
        saveText = "Save and Publish";
      }
      return saveText;
    },
  },
  watch: {
    dateFormatted(val) {
      this.caseDate = moment(val, "YYYY-MM-DD").format("LL");
    },
    file(val) {
      if (val) {
        this.fileName = val.name;
      } else {
        this.fileName = "";
      }
    },
    issues(newValue, oldValue) {
      if (!Array.isArray(newValue) || newValue.length === 0) {
        const statusRef = "";
        const landmarkRef = "";
        this.handleTagVisibilityUpdated(statusRef, landmarkRef);
        // console.log('Issues table is empty or has no value.');
      } else {
        const statusRef = oldValue[0].status;
        const landmarkRef = oldValue[0].landmark;
        this.handleTagVisibilityUpdated(statusRef, landmarkRef);
        // console.log('Issues table has a value: status', oldValue[0].status);
        // console.log('Issues table has a value: landmark', oldValue[0].landmark);
      }
    },
  },
};
</script>