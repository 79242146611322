<template>
  <v-data-table
    class="elevation-1 mx-1"
    hide-default-footer
    :headers="headers"
    :items="localData"
  >
    <template v-slot:top>
      <v-toolbar 
        color="secondary" 
        flat
        rounded 
      >
        <v-toolbar-title class="white--text">List of Issues and Rulings</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn 
              class="ms-2 primary"
              small
              rounded
              v-on="on"
            >
              Add Issue
            </v-btn>
          </template>
          <v-form v-model="dialogValid" ref="dialog">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="6" sm="4" md="4">
                      <v-text-field
                        v-model="editedItem.issueId"
                        label="Issue #"
                        :rules="fieldRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="4" md="4">
                      <v-select
                        v-model="editedItem.status"
                        :items="statuses1"
                        label="Status"
                        :rules="fieldRules"
                        @input="updateTagVisibility"
                      />
                      <!-- <span class="subtitle-2">Tier Classification</span>
                      <v-chip-group>
                        <v-chip outlined small v-if="tag1">{{ caseTag[0] }}</v-chip>
                        <v-chip outlined small v-if="tag2">{{ caseTag[1] }}</v-chip>
                        <v-chip outlined small v-if="tag3">{{ caseTag[2] }}</v-chip>
                        <v-chip outlined small v-if="tag4">{{ caseTag[3] }}</v-chip>
                      </v-chip-group> -->
                    </v-col>
                    <v-col col="6" sm="4" md="4">
                      <v-select
                        v-model="editedItem.landmark"
                        :items="statuses2"
                        label="Landmark"
                        :rules="fieldRules"
                        @input="updateTagVisibility"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.issue"
                        label="Issue"
                        :rules="fieldRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-textarea
                        v-model="editedItem.ruling"
                        label="Ruling"
                        :rules="fieldRules"
                        dense
                        outlined
                        solo-filled
                        auto-grow
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-textarea
                        v-model="editedItem.syllabus"
                        label="Salient Doctrines or Principles"
                        dense
                        outlined
                        solo-filled
                        :rules="fieldRules"
                        auto-grow
                        
                        clearable
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <v-divider class="my-2"></v-divider>
                  <v-subheader>Links</v-subheader>
                  <v-row v-for="(link, idx) in editedItem.links" :key="idx">
                    <v-col cols="12" sm="3" md="3">
                      <v-autocomplete
                        v-model="link.caseRef"
                        @input="updateCaseRef($event, idx)"
                        :readonly="link.readonly"
                        :items="cases"
                        label="Case Reference"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="5" md="5">
                      <v-select
                        v-model="link.issueRef"
                        @change="updateIssueRef($event, idx)"
                        :readonly="link.readonly"
                        :items="issueList[idx]"
                        label="Issue Reference"
                        :rules="[validateIssues(link.issueRef, idx)]"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <v-container>
                        <v-btn icon>
                          <v-icon @click="addLink()">mdi-playlist-plus</v-icon>
                        </v-btn>
                        <v-btn icon>
                          <v-icon @click="removeLink(idx)">mdi-delete-outline</v-icon>
                        </v-btn>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" small outlined @click="cardClose"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  @click="cardSave"
                  small
                  :disabled="!dialogValid"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil-outline</v-icon>
      <v-icon small @click="deleteItem(item)">mdi-delete-outline</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { db } from "../../firebaseDb";
export default {
  props: {
    items: { type: Array },
  },
  beforeMount() {
    this.localData = this.items;
    // this.updateExistingItems();
  },
  computed: {
    ...mapState(["cases"]),
    ...mapGetters(["darkTheme"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Issue" : "Edit Issue";
    },
  },
  data() {
    return {
      // existingItems: [],
      // show: false,

      localData: "",
      fieldRules: [(v) => !!v || "Field is required"],
      // everything related to the issues table
      sortDesc: false,
      headers: [
        {
          text: "Issue #",
          align: "start",
          sortable: false,
          value: "issueId",
        },
        { text: "Status", value: "status", sortable: false },
        { text: "Content", value: "issue", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      dialog: false,
      dialogValid: true,
      editedIndex: -1,
      editedItem: {
        issueId: 0,
        status: "Controlling",
        landmark: "Not Landmark",
        links: [
          {
            caseRef: "",
            issueRef: "",
            readonly: false,
          },
        ],
      },
      defaultItem: {
        issueId: 0,
        status: "Controlling",
        landmark: "Not Landmark",
        links: [
          {
            caseRef: "",
            issueRef: "",
            readonly: false,
          },
        ],
      },
      caseText: "",
      // TODO: move to settings
      statuses1: ["Controlling", "Repealed", "Reinstated"],
      statuses2: ["Landmark", "Not Landmark"],
      valcheck: "",
      caselink: [],
      selectIssues: [],
      issueList: [],
      tag1: "",
      tag2: "",
      tag3: "",
      tag4: "",
    };
  },
  watch: {
    dialog(val) {
      val || this.cardClose();
      if (this.editedItem.issueId == 0) {
        this.editedItem.issueId = this.localData.length + 1;
      }
    },
    //items(newVal, oldVal) {
    items() {
      this.localData = this.items;
      //console.log("Issues prop change: ", newVal, " | was: ", oldVal);
    },
    // localData() {
    //   this.updateExistingItems(); // Update existingItems whenever localData changes
    // },
    'editedItem.status': 'updateTagVisibility',
  },
  created() {
    this.updateTagVisibility();
  },
  methods: {
    updateTagVisibility() {
      // Logic to update tag visibility based on editedItem.status and editedItem.landmark
      this.tag1 = this.editedItem.status === 'Controlling' && this.editedItem.landmark === 'Landmark';
      this.tag2 = this.editedItem.status === 'Controlling' && this.editedItem.landmark === 'Not Landmark';
      this.tag3 = this.editedItem.status === 'Controlling' || this.editedItem.status === 'Reinstated' && (this.editedItem.landmark === 'Landmark' || this.editedItem.landmark === 'Not Landmark');
      this.tag4 = this.editedItem.status === 'Reinstated' || this.editedItem.status === 'Controlling' || this.editedItem.status === 'Repealed' && (this.editedItem.landmark === 'Landmark' || this.editedItem.landmark === 'Not Landmark');

    },
    // selectItem(item) {
    //   // Select item from autocomplete options
    //   this.editedItem.syllabus = item;
    //   this.show = false;
    // },
    // updateExistingItems() {
    //   // Extract syllabus content from each item in localData and split it by lines
    //   const syllabusContent = this.localData.map(item => item.syllabus).join('\n');

    //   // Split the content into lines and trim each line
    //   const lines = syllabusContent.split('\n').map(line => line.trim());

    //   // Filter out duplicate lines
    //   const uniqueLines = [...new Set(lines)];

    //   this.existingItems = uniqueLines;
    // },
    updateCaseRef(event, idx) {
      let links = JSON.parse(JSON.stringify(this.editedItem.links[idx]));
      db.doc("cases/" + event)
        .get()
        .then((x) => {
          links.date = x.data().date.seconds;
          this.$set(this.editedItem.links, idx, links);
        });
      this.retrieveIssues(event, idx);
    },
    updateIssueRef(event, idx) {
      let links = JSON.parse(JSON.stringify(this.editedItem.links[idx]));
      // console.log("updateIssueRef " + idx);
      // console.log(event);
      db.doc("issues/" + event)
        .get()
        .then((x) => {
          let data = x.data().related;
          // console.log(data);
          // console.log(this.editedItem.links[idx]);
          if (typeof data != "undefined") {
            links.related = data.id;
            this.$set(this.editedItem.links, idx, links);
          }
        });
      this.editedItem.links[idx].updated = true;
    },
    validateIssues(value, idx) {
      // newly created links will not have a value
      // if (typeof value == "undefined") {
      //   return true;
      // }
      if (this.editedItem.links.caseRef != null && value == "") {
        return "Field is required";
      }
      // make sure the only existence of the value is the one being entered by the user
      if (this.editedItem.links.findIndex((x) => x.issueRef == value) == idx) {
        let temp = this.editedItem.links.filter((val, index, self) => {
          return (
            index <= idx &&
            self.findIndex((v) => v.related === val.related) === index
          );
        });
        let unique = temp.filter((v) => typeof v.related != "undefined");
        // list of issuelinks cannot be more than 1
        if (unique.length <= 1) {
          return true;
        } else {
          return "Issue belongs to another set of links. Go to Case to delete link.";
        }
      } else {
        return "Issue is already entered above";
      }
    },
    //retrieve the list of issues from the given GRNO
    retrieveIssues(param, idx) {
      db.doc("cases/" + param)
        .get()
        .then((c) => {
          let issuedata = [];
          let caseData = c.data();
          //go through each of the issues array
          caseData.issues.forEach((e) => {
            // retrieve the issue data from the firebase reference (using the database record retrieved above)
            e.get().then((res) => {
              let issueData = res.data();
              // this if statement is supposed to stop showing issues that have already been linked to a different issuelink
              // if (
              //   (typeof issueData.related != "undefined" &&
              //     typeof this.editedItem.related == "undefined") ||
              //   (typeof issueData.related == "undefined" &&
              //     typeof this.editedItem.related != "undefined") ||
              //   (typeof issueData.related == "undefined" &&
              //     typeof this.editedItem.related == "undefined") ||
              //   issueData.related.id == this.editedItem.related.id
              // ) {
              //create the temp data with text and value for the SELECT component (dropdown box)
              let tempdata = {
                text: issueData.issueId + ". " + issueData.issue,
                value: res.id,
              };
              issuedata.push(tempdata);
              // }
            });
          });
          this.$set(this.issueList, idx, issuedata);
        });
    },
    // everything related to the issues table
    // this function opens the dialog for editing
    editItem(item) {
      this.editedIndex = this.localData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      // need to copy the array without the reference
      // otherwise changes to the editeditems will always propagate to the main array
      this.editedItem.links = JSON.parse(JSON.stringify(item.links)); // rude way of doing it but it works
      if (typeof this.editedItem.links == "undefined") {
        this.addLink(0);
      } else {
        this.editedItem.links.forEach((val, idx) => {
          if (val.caseRef != "") {
            this.retrieveIssues(val.caseRef, idx);
          }
        });
      }
    },
    deleteItem(item) {
      const index = this.localData.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.$emit("deleteIssue", { index: index });
      //this.localData.splice(index, 1);
    },
    cardClose() {
      if (this.dialog) {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedItem.issueId = this.localData.length + 1;
          this.editedIndex = -1;
        });
        if (typeof this.editedItem.temp != "undefined") {
          delete this.editedItem.temp;
        }
        //console.log("closing dialog");
        //console.log(this.editedItem);
      }
    },
    cardSave() {
      // saving data emits an event for the caller to do the adding of subscription to the user
      if (typeof this.editedItem.temp != "undefined") {
        this.editedItem.deleted = JSON.parse(
          JSON.stringify(this.editedItem.temp)
        );
      }
      this.$emit("newIssue", {
        editedIndex: this.editedIndex,
        editedItem: this.editedItem,
      });
      this.updateTagVisibility,
      //   if (this.editedIndex > -1) {
      //     Object.assign(this.localData[this.editedIndex], this.editedItem);
      //   } else {
      //     this.localData.push(this.editedItem);
      //   }
      this.cardClose();
    },
    // links that are part of the issues
    addLink() {
      let checkEmptyLinks = this.editedItem.links.filter((link) => {
        return link.caseRef === "" || link.caseRef === null;
      });
      if (checkEmptyLinks.length >= 1 && this.editedItem.links.length > 0) {
        return;
      }
      if (this.dialogValid) {
        this.editedItem.links.push(
          JSON.parse(JSON.stringify(this.defaultItem.links[0]))
        );
      }
    },
    removeLink(lineId) {
      // need to store this info
      if (typeof this.editedItem.temp == "undefined") {
        this.editedItem.temp = [];
        this.$set(this.editedItem.temp, 0, this.editedItem.links[lineId]);
      } else {
        this.editedItem.temp.push(
          JSON.parse(JSON.stringify(this.editedItem.links[lineId]))
        );
      }
      if (this.editedItem.links.length > 1) {
        this.editedItem.links.splice(lineId, 1);
      } else {
        this.$set(this.editedItem.links, 0, this.defaultItem.links[0]);
      }
    },
  },
};
</script>
